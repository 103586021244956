import { rejects } from "assert";
import { resolve } from "path";

var firebase = require("firebase/app");
// Add additional services that you want to use
require("firebase/firebase-auth");
require("firebase/firebase-database");
require("firebase/firebase-firestore");
require("firebase/firebase-messaging");
require("firebase/firebase-storage");
require("firebase/firebase-functions");

const firebaseConfig = {
  apiKey: "AIzaSyBOHne_7LL7yF_QWfHdbnASJraP6lJZ9kI",
  authDomain: "fpusa-tournament-bid.firebaseapp.com",
  databaseURL: "https://fpusa-tournament-bid.firebaseio.com",
  projectId: "fpusa-tournament-bid",
  storageBucket: "fpusa-tournament-bid.appspot.com",
  messagingSenderId: "146784704037",
  appId: "1:146784704037:web:095e84f26b946c6dfc11cc",
  measurementId: "G-RWT4C3H9CG",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

// firebase.firestore().disableNetwork()
// .then(function() {
//     // Do offline actions
//     // ...
// });

// firebase.firestore().enableNetwork()
// .then(function() {
//     // Do online actions
//     // ...
// });

var db = firebase.firestore();
var unsubscribeBids;
var unsubscribeAdminBids;
var year = 2025;

export default function service(app) {
  onClubUpdate();
  // listAuthUsers();

  // function listAuthUsers() {
  //     let listUsers = firebase.functions().httpsCallable('listAuthUsers');

  //     listUsers()
  //     .then( data =>{
  //         console.log("users", data)
  //     })

  // }

  // AUTH
  function userSignedIn(user) {
    app.ports.onAuthStateChanged.send(user);
    getEvents(year);
    if (user.isAdmin) {
      getAdminBids(db, year);
    } else {
      getMyBids(db, user.uid, user.clubId, year);
    }
  }

  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      return firebase.auth().signInWithEmailAndPassword(email, password);
    })
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
    });

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in.
      console.log("Signed in");

      var docRef = db.collection("users").doc(user.uid);

      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          // console.log("Claims", idTokenResult.claims)
          if (!!idTokenResult.claims.admin) {
            return true;
          } else {
            return false;
            // Show regular user UI.
          }
        })
        .then((isAdmin) => {
          docRef.get().then((doc) => {
            if (doc.exists) {
              let profile = doc.data();
              profile.isAdmin = isAdmin;
              profile.uid = user.uid;
              userSignedIn(profile);
            } else {
              app.ports.onMissingProfile.send(user);
              console.log("No profile found! Let's fix it.");
            }
          });
        })
        .catch(function (error) {
          app.ports.onAuthStateChanged.send({});
          console.log(error);
        });
    } else {
      app.ports.onAuthStateChanged.send({});
      console.log("Not signed in");
    }
  });

  app.ports.signOut.subscribe(() => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        if (unsubscribeBids) {
          unsubscribeBids();
        }

        if (unsubscribeAdminBids) {
          unsubscribeAdminBids();
        }

        console.log("Successfully logout!");
      });
  });

  app.ports.signInWithEmailAndPassword.subscribe((data) => {
    var email = data.email;
    var password = data.password;

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;

        if (errorCode === "auth/wrong-password") {
          app.ports.onGotAlert.send({
            message: errorMessage,
            kind: "error",
            inputField: "password",
          });
          console.log("Invalid password.");
        } else {
          app.ports.onGotAlert.send({
            message: errorMessage,
            kind: "error",
            inputField: "",
          });
          console.log(errorMessage);
        }
        console.log(error);
      });
  });

  app.ports.fetchSignInMethodsForEmail.subscribe((email) => {
    firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then(function (providers) {
        app.ports.gotSignInMethods.send({ methods: providers });
        // console.log(providers)
      });
  });

  app.ports.updateProfile.subscribe((data) => {
    // do I need to check if user is Admin here?
    console.log("updateProfile");
    let user = firebase.auth().currentUser;
    let userId = user.uid;
    let clubId = data.clubId;
    // let clubDocRef = db.collection("clubs").doc(club.id);
    db.collection("users")
      .doc(userId)
      .set({ clubId: clubId })
      .then(() => {
        user.clubId = clubId;
        userSignedIn(user);
      })
      .catch((error) => {
        // Handle Errors here.
        let errorMessage = error.message;
        app.ports.onGotAlert.send({
          message: errorMessage,
          kind: "error",
          inputField: "",
        });
        console.log(error);
      });
  });

  app.ports.createUserWithEmailAndPassword.subscribe((data) => {
    let email = data.email;
    let password = data.password;
    let club = data.clubDocRef;
    let displayName = data.displayName;

    let clubDocRef = db.collection("clubs").doc(club.id);
    let cred;

    // console.log( email, password, club, clubDocRef);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        cred = result;
        return db
          .collection("users")
          .doc(cred.user.uid)
          .set({ clubId: club.id, displayName: displayName, email: email });
      })
      .then(() => {
        return cred.user.updateProfile({
          displayName: displayName,
        });
      })
      .then(() => {
        //Success
      })
      .catch((error) => {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode === "auth/weak-password") {
          app.ports.onGotAlert.send({
            message: errorMessage,
            kind: "error",
            inputField: "password",
          });
        } else {
          app.ports.onGotAlert.send({
            message: errorMessage,
            kind: "error",
            inputField: "",
          });
        }
        console.log(error);
      });
  });

  // BID
  // app.ports.getBids.subscribe( data => {
  //     clubRef = db.doc(data.clubRef.path);
  //     getBids(db, data.uid, clubRef, 2022)
  // });

  app.ports.saveBid.subscribe((data) => {
    let docId = data.id;

    // clean up
    delete data.id;
    let dates = [];
    data.dates.forEach((ts) => {
      let date = firebase.firestore.Timestamp.fromMillis(ts);
      dates.push(date);
    });

    data.dates = dates;
    // data.clubId = db.doc(data.clubRef.path);
    // data.eventId = db.doc(data.eventRef.path);

    let now = firebase.firestore.Timestamp.now();
    if (data.dateCreated == 0) {
      data.dateCreated = now;
      data.dateModified = now;
    } else {
      data.dateCreated = firebase.firestore.Timestamp.fromMillis(
        data.dateCreated
      );
      data.dateModified = now;
    }

    if (docId === "") {
      db.collection("bids")
        .add(data)
        .then(() => {
          console.log("Bid saved");
          // app.ports.onGotAlert.send({ message : "Bid saved", kind: 'success', inputField: ""});
        })
        .catch(function (error) {
          app.ports.onGotAlert.send({
            message: "Could not save your bid!",
            kind: "error",
            inputField: "",
          });
          console.error("Error saving bid: ", error);
        });
    } else {
      db.collection("bids")
        .doc(docId)
        .update(data)
        .then(() => {
          console.log("Bid updated");
          // app.ports.onGotAlert.send({ message : "Your bid was updated", kind: 'success', inputField: ""});
        })
        .catch(function (error) {
          app.ports.onGotAlert.send({
            message: "Could not update your bid!",
            kind: "error",
            inputField: "",
          });
          console.error("Error updating bid: ", error);
        });
    }

    console.log(data);
  });

  // TODO: this needs to be rewritten to support seasons
  app.ports.updateBidStatus.subscribe((data) => {
    // do I need to check if user is Admin here?
    console.log("updateBidStatus");
    let bid = data.bid;
    let bidRef = db.collection("bids").doc(bid.id);

    if (
      data.status == "Submitted" ||
      data.status == "In Review" ||
      data.status == "Rejected"
    ) {
      bidRef
        .update({ status: data.status })
        .then(() => {
          console.log("Bid status updated to", data.status);
        })
        .catch((error) => {
          // Handle Errors here.
          let errorMessage = error.message;
          app.ports.onGotAlert.send({
            message: errorMessage,
            kind: "error",
            inputField: "",
          });
          console.log(error);
        });
    } else if (data.status == "Approved") {
      // meaning its
      let batch = db.batch();

      let dates = [];
      bid.dates.forEach((ts) => {
        let date = firebase.firestore.Timestamp.fromMillis(ts);
        dates.push(date);
      });

      let eventRef = db.collection("events").doc(bid.eventId);

      batch.update(bidRef, { status: data.status });
      batch.update(eventRef, {
        clubName: bid.bidder.clubName,
        location: bid.location,
        dates: dates,
        clubId: bid.clubId,
        bidId: bid.id,
        status: "Assigned",
      });

      // Commit the batch
      batch
        .commit()
        .then(function () {
          console.log("Bid approve batch committed");
        })
        .catch((error) => {
          // Handle Errors here.
          let errorMessage = error.message;
          app.ports.onGotAlert.send({
            message: errorMessage,
            kind: "error",
            inputField: "",
          });
          console.log(error);
        });
    } else {
      console.log("Unknown status: " + data.status);
    }
  });

  app.ports.updateEvent.subscribe((data) => {
    // do I need to check if user is Admin here?
    // TODO : Need to clean up if there was an Approved Bid
    console.log("updateEvent");

    var docId = data.id;

    var updata;

    if (data.status == "Unassigned") {
      updata = {
        title: data.title,
        status: data.status,
        clubName: "",
        location: "",
        dates: [],
      };
    } else {
      let dates = [];
      data.dates.forEach((ts) => {
        let date = firebase.firestore.Timestamp.fromMillis(ts);
        dates.push(date);
      });

      updata = {
        title: data.title,
        status: data.status,
        clubName: data.clubName,
        location: data.location,
        dates: dates,
      };
    }

    db.collection("events")
      .doc(docId)
      .update(updata)
      .then(() => {
        console.log("event", docId, "set to unassigned, club info removed");
      })
      .catch((error) => {
        // Handle Errors here.
        let errorMessage = error.message;
        app.ports.onGotAlert.send({
          message: errorMessage,
          kind: "error",
          inputField: "",
        });
        console.error(error);
      });
  });

  app.ports.sendPasswordResetEmail.subscribe(function (email) {
    console.log("Trying to password reset.");
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        app.ports.onGotAlert.send({
          message: "Password reset email sent",
          kind: "info",
        });
        console.log("Password reset email sent.");
        // Password reset email sent.
      })
      .catch(function (error) {
        app.ports.onGotAlert.send({ message: error.message, kind: "error" });
        console.error(error);
      });
  });

  app.ports.deleteBid.subscribe((docId) => {
    db.collection("bids")
      .doc(docId)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        app.ports.onGotAlert.send({
          message: error,
          kind: "error",
          inputField: "",
        });
        console.error("Error removing document: ", error);
      });
  });

  // FUNCTIONS
  function onClubUpdate() {
    console.log("onClubUpdate");
    db.collection("clubs").onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        let doc = change.doc;
        let club = doc.data();
        club["id"] = doc.id;
        app.ports.onGotClub.send(club);
        // console.log(club.id, club);
      });
    });
  }

  var userIdAdmin = function () {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        // console.log("Claims", idTokenResult.claims)
        if (!!idTokenResult.claims.admin) {
          console.log("admin true");
          return true;
        } else {
          return false;
          // Show regular user UI.
        }
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  function showMe() {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        console.log("Claims", idTokenResult.claims);
        if (!!idTokenResult.claims.admin) {
          // Show admin UI.
          console.log("Is admin");
        } else {
          // Show regular user UI.
          console.log("No claims", idTokenResult.claims);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // function getSeason() {
  //     let yearStr = year.toString()
  //     let seasonRef = db.collection('seasons').doc(yearStr);
  //     seasonRef.get().then( doc =>{
  //         if (doc.exists) {
  //             let season = doc.data();
  //             season.id = doc.id;
  //             app.ports.onGotSeason.send(season);
  //         } else {
  //             app.ports.onGotAlert.send({ message :"Season not available. Contact admin.", kind: 'error', inputField: ""});
  //             console.log("Oops! No season found!");
  //         }
  //     })
  // }

  function getEvents() {
    db.collection("events")
      .where("year", "==", year)
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          let doc = change.doc;
          let event = doc.data();
          event["id"] = doc.id;

          let dates = [];
          if (event["dates"]) {
            event.dates.forEach((date) => {
              let ts = Math.floor(date.toMillis());
              dates.push(ts);
            });
          }
          event["dates"] = dates;

          app.ports.onGotEvent.send(event);
          // console.log(event);
        });
      });
  }

  function getMyBids(db, uid, clubId, year) {
    // console.log("getMyBids", clubId, uid, year)
    unsubscribeBids = db
      .collection("bids")
      .where("year", "==", year)
      .where("clubId", "==", clubId)
      .where("bidderUid", "==", uid)
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          let doc = change.doc;
          if (change.type == "removed") {
            console.log("Removed ", doc.id);
            app.ports.onBidRemoved.send(doc.id);
          } else {
            let bid = doc.data();
            bid["id"] = doc.id;
            let dates = [];
            bid.dates.forEach((date) => {
              let ts = Math.floor(date.toMillis());
              dates.push(ts);
            });
            bid["dates"] = dates;
            if (bid["dateCreated"]) {
              bid["dateCreated"] = Math.floor(bid["dateCreated"].toMillis());
            }
            if (bid["dateModified"]) {
              bid["dateModified"] = Math.floor(bid["dateModified"].toMillis());
            } else {
              bid["dateModified"] = bid["dateCreated"];
            }

            app.ports.onGotBid.send(bid);
            console.log(bid);
          }
        });
      });
  }

  function getAdminBids(db, year) {
    console.log("ALL BIDS");
    unsubscribeAdminBids = db
      .collection("bids")
      .where("year", "==", year)
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          let doc = change.doc;
          if (change.type == "removed") {
            console.log("Removed ", doc.id);
            app.ports.onBidRemoved.send(doc.id);
          } else {
            let bid = doc.data();
            bid["id"] = doc.id;
            let dates = [];
            bid.dates.forEach((date) => {
              let ts = Math.floor(date.toMillis());
              dates.push(ts);
            });
            bid["dates"] = dates;
            if (bid["dateCreated"]) {
              bid["dateCreated"] = Math.floor(bid["dateCreated"].toMillis());
            }
            if (bid["dateModified"]) {
              bid["dateModified"] = Math.floor(bid["dateModified"].toMillis());
            } else {
              bid["dateModified"] = bid["dateCreated"];
            }

            app.ports.onGotBid.send(bid);
            console.log(bid);
          }
        });
      });
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  function formateDate(date) {
    return (
      months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
    );
  }

  function createEvents(userId) {
    var createEvents = firebase.functions().httpsCallable("createEvents");
    let data = { year: year };

    createEvents(data)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.error("Error", "createEvents", error);
      });
  }

  function setUserAsAdmin(userId) {
    var setAdmin = firebase.functions().httpsCallable("setUserAsAdmin");
    let data = { uid: userId };

    setAdmin(data)
      .then((result) => {
        console.log("setAdmin result", result);
      })
      .catch((error) => {
        console.log("Error", "setUserAsAdmin", error);
      });
  }

  // setUserAsAdmin("rVFUJJCKuoeUDwxwGbi9YK5gRQX2");

  // addEventsForYear(2022);

  // ADMIN

  //   makeEvents();

  function makeEvents() {
    let yearStr = year.toString();
    let eventsRef = db.collection("events");
    let title = yearStr + " FPUSA Events";
    let events = [];

    let batch = db.batch();

    let regions = [
      "Northeast",
      "Mid-America",
      "Southeast",
      "Central",
      "Southwest",
      "Northern California",
      "Northwest",
      // , "Independent"
    ];

    let regionals = [
      "Regional Men's Triples",
      "Regional Women's Triples",
      "Regional Select Triples",
      "Regional Men's Doubles",
      "Regional Women's Doubles",
      "Regional Select Doubles",
      "Regional Mixed Doubles",
      "Regional Mixed Triples",
      "Regional Men's Singles",
      "Regional Women's Singles",
      "Regional Select Singles",
    ];

    // let nationals = [
    //   "National Men's Triples",
    //   "National Women's Triples",
    //   "National Men's Doubles",
    //   "National Women's Doubles",
    //   "National Mixed Doubles",
    //   "National Men's Singles",
    //   "National Women's Singles",
    //   "National Mixed Triples",
    // ];

    let nationals = [
      "National Doubles Package (2-day) - Women's & Men's Doubles / Mixed Doubles",
      "National Singles & Mixed Triples Package (2-day) - Women's & Men's Singles / Mixed Triples",
      "National Men's Triples (2-day)",
    ];

    let qualifiers = [
      //   "World Championship Men's Triples Qualifier",
      //   "World Championship Singles/Doubles Qualifiers",
      "World Championship Women's Triples Qualifier (2-day)",
      "World Championship Women's Doubles Qualifier (2-day)",
      "World Championship Men's Doubles Qualifier (2-day)",
    ];

    // regions.forEach((regionName) => {
    //   regionals.forEach((eventTitle) => {
    //     // let id = db.collection('events').doc().id;

    //     let eventName = regionName + " " + eventTitle;
    //     let event = {
    //       title: eventName,
    //       kind: regionName,
    //       status: "Unassigned",
    //       dateCount: 1,
    //       year: year,
    //     };
    //     let eventRef = eventsRef.doc();
    //     batch.set(eventRef, event);
    //     events.push(event);
    //     // console.log("event", event)
    //   });
    // });

    nationals.forEach((eventTitle) => {
      // let id = db.collection('events').doc().id;
      let event = {
        title: eventTitle,
        kind: "National",
        status: "Unassigned",
        dateCount: 2,
        year: year,
      };
      let eventRef = eventsRef.doc();
      batch.set(eventRef, event);
      events.push(event);
    });

    qualifiers.forEach((eventTitle) => {
      // let id = db.collection('events').doc().id;
      let event = {
        title: eventTitle,
        kind: "Qualifier",
        status: "Unassigned",
        dateCount: 2,
        year: year,
      };
      let eventRef = eventsRef.doc();
      batch.set(eventRef, event);
      events.push(event);
    });

    let data = { title: title, events: events, status: 1 };

    console.log("data", data);

    batch
      .commit()
      .then(() => {
        console.log("Events committed");
      })
      .catch((error) => {
        // Handle Errors here.
        let errorMessage = error.message;
        // app.ports.onGotAlert.send({ message : errorMessage, kind: 'error', inputField: ""});
        console.log(error, errorMessage);
      });
  }
}
