export default function service(app, storageKey) {

    window.onscroll = function(){
        app.ports.onScroll.send(1);
    }
    
    app.ports.setOverflow.subscribe( val => {
        document.getElementsByTagName("body")[0].style.overflowY = val; 
    });

    // app.ports.storeCache.subscribe( val => {

    //     if (val === null) {
    //         localStorage.removeItem(storageKey);
    //     } else {
    //         localStorage.setItem(storageKey, JSON.stringify(val));
    //     }
      
    //     // Report that the new session was stored succesfully.
    //     // setTimeout(function() { app.ports.onStoreChange.send(val); }, 0);
    //   });
      
    //   // Whenever localStorage changes in another tab, report it if necessary.
    //   window.addEventListener("storage", function(event) {
    //     if (event.storageArea === localStorage && event.key === storageKey) {
    //         if (event.newValue !== null) {
    //             app.ports.onLocalCacheChanged.send(event.newValue);
    //         }
    //     }
    //   }, false);

}