import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './Js/serviceWorker';
import firebaseService from './Js/firebaseService';
import localService from './Js/localService';

var storageKey = "fpusa";
var storage = localStorage.getItem(storageKey);

var randomArray = new Uint32Array(1);
window.crypto.getRandomValues(randomArray);

let flags =
  { storage : storage
  , seed : randomArray[0]
  }



var app =
  Elm.Main.init({
  node: document.getElementById('root'),
  flags: flags
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

firebaseService(app);
localService(app, storageKey);
serviceWorker.unregister();